<template>
  <div ref="parent">
    <a-modal
      centered
      :width="504"
      :title="modalTitle"
      :visible="visible"
      @cancel="handleCancel"
      :getContainer="() => $refs.parent"
    >
      <a-form ref="formRef" :rules="rules" :model="modalVal">
        <a-form-item label="角色名称" name="roleName" :labelCol="{ span: 5 }" class="form-item">
          <a-input
            :maxlength="20"
            placeholder="请输入角色名称"
            v-model:value="modalVal.roleName"
            style="width: 360px"
          />
        </a-form-item>
        <a-form-item label="角色说明" name="mark" :labelCol="{ span: 5 }" class="form-item">
          <a-textarea
            v-model:value="modalVal.mark"
            placeholder="请输入角色说明"
            :maxlength="100"
            :autoSize="{ minRows: 3 }"
            style="width: 360px"
          ></a-textarea>
        </a-form-item>
        <a-form-item label="权限" name="power" :labelCol="{ span: 5 }" style="margin-bottom: 0" class="powers">
          <h4 style="margin:20px 0 10px">功能权限</h4>
          <a-tree
            class="md-tree"
            v-model:checkedKeys="modalVal.power"
            checkable
            :expanded-keys="expandedKeys"
            :auto-expand-parent="autoExpandParent"
            :selected-keys="selectedKeys"
            :tree-data="permissionList"
            @expand="onExpand"
            @check="onCheck"
            :replaceFields="{ children: 'child', title: 'name', key: 'id' }"
          >
            <template #switcherIcon>
              <span
                class="tree-arrow"
                style="
                  width: 7px;
                  background: transparent;
                  height: 7px;
                  transform: rotateZ(225deg);
                  border-left: 1px solid #333333;
                  border-top: 1px solid #333333;
                "
              ></span>
            </template>
          </a-tree>
          <h4 style="margin:10px 0 8px">
            数据权限
            <a-tooltip placement="right" color="#FFFFFF" trigger="hover" :getPopupContainer="node => node" class="line-tooltip">
              <template #title>
                <span style="color: #333333"
                  >数据权限：可以设置角色的用印数据权限查看范围，可指定查看某一个或多个部门的用印数据</span
                >
              </template>
              <QuestionCircleOutlined class="charts-icon" />
            </a-tooltip>
          </h4>
          <section class="power">
            <div style="width:130px">
              <span>用印数据查看范围：</span>
              <div
                @click="powerVisible = true"
                style="cursor: pointer;padding-left: 20px"
                v-if="modalVal.deptIds.length == 0"
              >
                <span style="color:#1890ff;margin-right:5px">设置数据权限</span>
                <EditOutlined />
              </div>
            </div>
            <div style="margin-left: 10px;flex:1">
              <template v-if="modalVal.deptIds.length">
                <span v-if="modalVal.dataScope == 1">全部部门</span>
                <span v-else>{{ departmentNames }}</span>
                <EditOutlined @click="powerVisible = true" style="color:#1890ff;margin-left:5px" />
              </template>
              <span style="color:rgba(0, 0, 0,.3)" v-else>默认查询该角色的用印数据</span>
            </div>
          </section>
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button @click="handleCancel" class="MyCancel">取消</a-button>
        <a-button class="comfirm" @click="comfirmAdd" :loading="loading">确定</a-button>
      </template>
      <Power
        v-model:visible="powerVisible"
        v-if="powerVisible"
        :selectID="modalVal.deptIds"
        :organizationalList="organizationalList"
        @selectedDepartment="selectedDepartment"
      />
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, toRefs, toRaw, watch, computed } from 'vue'
import Power from './power.vue'
import { EditOutlined, QuestionCircleOutlined } from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    Power,
    EditOutlined,
    QuestionCircleOutlined
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    permissionList: {
      type: Array,
      default: () => []
    },
    current: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'add'
    },
    loading: {
      type: Boolean,
      default: false
    },
    organizationalList: {
      type: Array,
      default: []
    }
  },
  setup(props, ctx) {
    const { emit } = ctx
    const formRef = ref()
    const titleObj = {
      add: '添加',
      edit: '修改'
    }
    const modalTitle = computed(() => titleObj[props.type])
    const modalVal = reactive({
      roleName: undefined,
      mark: undefined,
      power: [48, 49, 50, 51, 52, 53],
      deptIds: [],
      dataScope: 0
    })
    const state = reactive({
      expandedKeys: [],
      autoExpandParent: false,
      selectedKeys: [],
      powerVisible: false,
      departmentNames: ''
    })
    const rules = ref()
    rules.value = {
      roleName: [
        {
          required: true,
          message: '请输入角色名称'
        }
      ],
      power: [
        {
          type: 'array',
          required: true,
          message: '请输入权限'
        }
      ]
    }
    const comfirmAdd = () => {
      emit('update:loading', true)
      formRef.value
        .validate()
        .then(() => {
          emit('modelSubmit', toRaw(modalVal))
        })
        .catch(() => emit('update:loading', false))
    }
    const onExpand = expandedKeys => {
      state.expandedKeys = expandedKeys
      state.autoExpandParent = false
    }
    const handleCancel = () => {
      formRef.value.resetFields()
      emit('update:visible', false)
    }
    const onCheck = checkedKeys => {
      modalVal.power = checkedKeys
    }
    const selectedDepartment = (value, name, allIn) => {
      modalVal.deptIds = value
      state.departmentNames = name
      modalVal.dataScope = allIn
    }

    // watch(
    //   () => props.visible,
    //   () => {
    //     if (props.type === 'add') {
    //       modalVal.roleName = undefined
    //       modalVal.mark = undefined
    //       modalVal.power = [48, 49, 50, 51, 52, 53]
    //     } else if (props.type === 'edit') {
    //       modalVal.roleName = props.current.name
    //       modalVal.mark = props.current.roleExplain
    //       console.log(props.current.selected)
    //       modalVal.power = props.current.selected.map((item) => item.permissionsId)
    //       modalVal.deptIds = props.current.deptIds.map((item) => item.deptId)
    //       state.departmentNames = (props.current.deptIds.map((item) => item.deptName)).join("、");
    //     }
    //     state.expandedKeys = []
    //     state.autoExpandParent = false
    //   }
    // )
    watch(
      () => props.visible,
      newValue => {
        if (newValue) {
          state.departmentNames = ''
        }
      }
    )

    watch(
      () => props.current,
      () => {
        modalVal.roleName = props.current.userRole.name
        modalVal.mark = props.current.userRole.roleExplain
        modalVal.dataScope = props.current.dataScope
        modalVal.power = props.current.selected.map(item => item.permissionsId)
        modalVal.deptIds = props.current.deptIds.map(item => item.deptId)
        state.departmentNames = props.current.deptIds.map(item => item.deptName).join('、')
        state.expandedKeys = []
        state.autoExpandParent = false
      }
    )

    return {
      comfirmAdd,
      formRef,
      modalVal,
      rules,
      onExpand,
      ...toRefs(state),
      onCheck,
      modalTitle,
      handleCancel,
      selectedDepartment
    }
  }
})
</script>

<style lang="scss" scoped>
.comfirm {
  background: #c3161c;
  color: #fff;
  border: 1px solid transparent;
  margin-right: 0;
  &:hover {
    border-color: transparent;
  }
}

.form-item {
  margin-bottom: 16px;
}
.md-tree {
  width: 360px;
  background: #f7f8fa;
  max-height: 300px;
  overflow-y: scroll;

  :deep(.ant-checkbox-wrapper) {
    display: block;
    margin-left: 0;
  }
  :deep(.ant-tree-switcher) {
    left: 115px;
    transition: 0.3s;
  }
  :deep(.ant-tree-switcher_open) {
    transform: rotateZ(90deg);
  }
  :deep(li span.ant-tree-switcher, .ant-tree li span.ant-tree-iconEle) {
    width: 12px;
  }
}
.power {
  padding-left: 20px;
  display: flex;
  line-height: 20px;
  > div {
    display: inline-block;
  }
}

.line-tooltip {
  :deep(.ant-tooltip-content) {
    width: 280px;
  }
}
.charts-icon {
  font-size: 14px;
  color: #333333;
  cursor: pointer;
}

  .powers{
  line-height:20px;
  :deep(.ant-form-item-control-wrapper){
    width:360px
  }
}
</style>
